import React, { useEffect } from 'react';

function SplineViewer() {

  return (
    <div>
<iframe src='https://my.spline.design/untitled-3556f22d69d774b50e0f54ebac0a8c76/' frameborder='0' width='100%' height='100%'></iframe>
    </div>
  );
}

export default SplineViewer;
